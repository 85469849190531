<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input v-model="query.moduleName" clearable placeholder="组件名称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.moduleType" clearable placeholder="组件类型" class="filter-item" style="width: 120px" @change="toQuery">
      <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id"/>
    </el-select>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSMODULE_ALL','SRCMSMODULE_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增</el-button>
      <eForm ref="form" :is-add="true" :srCmsContentTypes="srCmsContentTypes"/>
    </div>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    },
    srCmsContentTypes: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      statusOptions: [
        { id: 0, name: '静态' },
        { id: 1, name: '动态' },
        { id: 2, name: '其他' }
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
