<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    :title="isAdd ? '新增' : '编辑'"
    width="600px"
  >
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="组件名称" prop="moduleName">
        <el-input v-model="form.moduleName" style="width: 200px;"/>
      </el-form-item>
      <el-form-item label="组件英文" prop="moduleEnglish">
        <el-input v-model="form.moduleEnglish" style="width: 200px;"/>
      </el-form-item>
      <el-form-item label="组件描述">
        <el-input type="textarea" autosize v-model="form.moduleDesc" style="width: 300px;"/>
      </el-form-item>
      <el-form-item label="组件风格">
        <el-input type="textarea" autosize v-model="form.moduleStyle" style="width: 300px;"/>
      </el-form-item>
      <el-form-item label="组件类型" prop="moduleType">
        <el-radio v-model="form.moduleType" label="0" @change="isShowContentTypeFunction(0)">静态组件</el-radio>
        <el-radio v-model="form.moduleType" label="1" @change="isShowContentTypeFunction(1)">动态组件</el-radio>
        <el-radio v-model="form.moduleType" label="2" @change="isShowContentTypeFunction(2)">其他</el-radio>
      </el-form-item>
      <el-form-item label="内容类型" v-if="isShowContentType" prop="contentType">
        <treeselect
          v-model="form.contentType"
          :options="srCmsContentTypes"
          style="width: 300px;"
          placeholder="选择内容类型"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label="1">正常</el-radio>
        <el-radio v-model="form.status" label="0">锁定</el-radio>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" autosize v-model="form.remark" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit('form')">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/cms/srCmsModule";
import Treeselect from "@riophae/vue-treeselect";
import IconSelect from "@/components/IconSelect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect, IconSelect },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    srCmsContentTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isShowContentType:false,
      loading: false,
      dialog: false,
      form: {
        id: "",
        moduleName: "",
        moduleEnglish: "",
        moduleDesc: "",
        moduleStyle: "",
        moduleType: "",
        contentType:"0",
        createTime: "",
        upateTime: "",
        status: "1",
        remark: ""
      },
      rules: {
        moduleName: [
          { required: true, message: "请输入组件名称", trigger: "blur" },
          { min: 4, max: 40, message: "长度在 4 到 40 个字符(20个汉字)", trigger: "blur" }
        ],
        moduleEnglish: [
          { required: true, message: "请输入组件英文", trigger: "blur" },
          { min: 4, max: 40, message: "长度在 4 到 40 个字符(20个汉字)", trigger: "blur" }
        ],
        moduleType: [
          { required: true, message: "请选择组件类型", trigger: "blur" }
        ],
        contentType: [
          { required: true, message: "请选择内容类型", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    isShowContentTypeFunction(item){
      if(item==1){
        this.isShowContentType=true;
      }else{
        this.isShowContentType=false;
      }
    },
    cancel() {
      this.resetForm();
    },
    doSubmit(form) {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd(form);
      } else {
        this.doEdit(form);
      }
    },
    doAdd(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          add(this.form)
            .then(res => {
              this.resetForm();
              this.$notify({
                title: "添加成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        } else {
          this.loading = false;
        }
      });
    },
    doEdit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          edit(this.form)
            .then(res => {
              this.resetForm();
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.sup_this.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        } else {
          this.loading = false;
        }
      });
    },
    resetForm() {
      this.isShowContentType=false;
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        id: "",
        moduleName: "",
        moduleEnglish: "",
        moduleDesc: "",
        moduleStyle: "",
        moduleType: "",
        contentType:"0",
        createTime: "",
        upateTime: "",
        status: "1",
        remark: ""
      };
    }
  }
};
</script>

<style scoped>
</style>
