import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'cms/crm/srCmsContentType',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'cms/crm/srCmsContentType/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'cms/crm/srCmsContentType',
    method: 'put',
    data
  })
}

// 获取所有内容类型树
export function getSrCmsContentTypeTree() {
  return request({
    url: 'cms/crm/srCmsContentType/tree',
    method: 'get'
  })
}
